import * as React from "react";

/////////////
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ActivityTable(props) {
  let { AllActivity } = props;

  return (
    <TableContainer
      sx={{ width: "70%", margin: "10px auto", "& td": { border: 0 } }}
      component={Paper}
    >
      <Table aria-label="simple table">
        <TableHead
          sx={{
            backgroundColor: "#1976d2 !important",
            color: "white !important",
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Activity Type
            </TableCell>
            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Activity Code
            </TableCell>
            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Activity Quantity
            </TableCell>

            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
            Tooth Number
            </TableCell>

            {/*  <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Activity Price
            </TableCell> */}
            {props.deleteFlag ? <TableCell align="center"></TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {AllActivity.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center">{row.activityType=="gmdn"?"GMDN":row.activityType[0].toUpperCase()+row.activityType.slice(1)}</TableCell>
              <TableCell align="center">{row.activityCode}</TableCell>
              <TableCell align="center">{row.activityQuantity?row.activityQuantity:1}</TableCell>
               <TableCell align="center">{row.toothNumber}</TableCell>
              {props.deleteFlag ? (
                <TableCell align="center">
                  <DeleteIcon
                    onClick={(e) => {
                      props.deleteActivityElement(index);
                    }}
                  />
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
