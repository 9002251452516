import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";

export default function AddressForm(props) {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Patient Details
      </Typography>
      <Grid container spacing={3}>
        {/*   <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            required
            id="age"
            name="age"
            label="Age"
            fullWidth
            value={data.age ? data.age : ""}
            variant="standard"
            onChange={(e) => props.onChange(e)}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl required variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label">
              Age Unit
            </InputLabel>
            <Select
              required
              name="ageUnit"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={data.ageUnit}
              onChange={(e) => props.onChange(e)}
              label="Age Unit"
            >
              <MenuItem value="Month">Month</MenuItem>
              <MenuItem value="Year">Year</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <FormControl required /* variant="standard" */ fullWidth>
            <InputLabel id="gender">Gender</InputLabel>
            <Select
              name="gender"
              labelId="gender"
              id="gender"
              value={data && data.gender ? data.gender : ""}
              onChange={(e) => props.onChange(e)}
              label="Gender"
            >
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </Select>
          </FormControl>


          <FormControl required /* variant="standard" */ fullWidth style={{ "marginTop": "10px" }}>
            <InputLabel id="encounterType">Encounter Type</InputLabel>
            <Select
              name="encounterType"
              labelId="encounterType"
              id="encounterType"
              value={data && data.encounterType ? data.encounterType : ""}
              onChange={(e) => props.onChange(e)}
              label="Encounter Type"
            >

              {/* 
              <MenuItem value="1">1- Hospital Inpatient</MenuItem>
              <MenuItem value="2">2- Day Case</MenuItem>
              <MenuItem value="3">3- Emergency</MenuItem>
              <MenuItem value="4">4- Outpatient</MenuItem>
              <MenuItem value="5">5- Home Health Care</MenuItem>
              <MenuItem value="6">6- Primary Health Care</MenuItem>
              <MenuItem value="7">7- Pharmacy</MenuItem> */}

              <MenuItem value="IMP">IMP- Inpatient Encounter</MenuItem>
              <MenuItem value="AMB">AMB- Ambulatory</MenuItem>
              <MenuItem value="EMER">EMER- Emergency</MenuItem>

              <MenuItem value="VR">VR- Virtual</MenuItem>
              <MenuItem value="HH">HH- Home Health</MenuItem>


              <MenuItem value="SS">SS- Short Stay</MenuItem>






            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl required variant="standard" fullWidth>
            <TextField
              required
              id="date"
              name="birthdate"
              label="Birth Date"
              type="date"
              value={data && data.birthdate ? data.birthdate : ""}
              onChange={(e) => props.onChange(e)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
