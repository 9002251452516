import * as React from "react";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
/////////////

import ActivityTable from "./ActivityTable";
import AutoComplete from "./BayanKSAComponent/AutoComplete";
import { useEffect, useState } from "react";

export default function ActivityForm(props) {
  /* const [activityObj,  setActivityObj] = useState(props.activityObj);

  useEffect(() => {
    setActivityObj(props.activityObj);
  }, [props.activityObj]); */

  const [code, setCode] = React.useState(props.activityObj.activityCode);
  const [type, setType] = React.useState(props.activityObj.activityType);
  const [toothNumber, setToothNumber] = React.useState(
    props.activityObj.toothNumber
  );

  const [price, setPrice] = React.useState(props.activityObj.activityPrice);

  const [quantity, setQuantity] = React.useState(
    props.activityObj.activityQuantity
  );
  const onChangeSetCode = (value) => {
    setCode(value);
  };

  if (!type && props.activityObj.activityType) {
    setType(props.activityObj.activityType);
  }

  if (!code && props.activityObj.activityCode) {
    setCode(props.activityObj.activityCode);
  }

  if (!price && props.activityObj.activityPrice) {
    setPrice(props.activityObj.activityPrice);
  }

  if (!quantity && props.activityObj.activityQuantity) {
    setQuantity(props.activityObj.activityQuantity);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Activity
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl required variant="standard" fullWidth>
            <InputLabel id="activityType-label">Activity Type</InputLabel>
            <Select
              required
              name="activityType"
              labelId="activityType-label"
              id="activityType"
              onChange={(e) => {
                props.onChange(e);
                //forceUpdate();
                setType(props.activityObj.activityType);
              }}
              label="Activity Type"
              value={type ? type : ""}
            >
              <MenuItem value="procedure">Procedure</MenuItem>
              <MenuItem value="service">Service</MenuItem>
              <MenuItem value="laboratory">Laboratory</MenuItem>
              <MenuItem value="imaging">Imaging</MenuItem>
              <MenuItem value="transportation">Transportation</MenuItem>

              <MenuItem value="dental">Dental</MenuItem>
             {/*  <MenuItem value="ada">ADA</MenuItem> */}
              <MenuItem value="gmdn">GMDN</MenuItem>

              {/*   <MenuItem value="SBS">SBS</MenuItem> */}
              {/*  <MenuItem value="SFDA">SFDA</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoComplete
            onChangeSetCode={onChangeSetCode}
            flag={props.flag}
            id="activityCode"
            label="Activity Code"
            name="activityCode"
            onSelectCode={props.onSelectCode}
            suggestions={props.AllMasterActivity}
          />
        </Grid>
        {/*    <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            id="price"
            label="Price"
            name="activityPrice"
            fullWidth
            variant="standard"
            onChange={(e) => {
              props.onChange(e);
              ///forceUpdate();
              setPrice(props.activityObj.activityPrice);
            }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            value={price ? price : ""}
          />
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            required
            id="quantity"
            label="Quantity"
            name="activityQuantity"
            fullWidth
            variant="standard"
            onChange={(e) => {
              props.onChange(e);
              // forceUpdate();
              setQuantity(props.activityObj.activityQuantity);
            }}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            value={quantity ? quantity : ""}
          />
        </Grid>

        {type == "dental" ? (
          <Grid item xs={12} md={6}>
            <FormControl required variant="standard" fullWidth>
              <InputLabel id="toothNumber-label">Tooth Number</InputLabel>
              <Select
                required
                name="toothNumber"
                labelId="toothNumber-label"
                id="toothNumber"
                onChange={(e) => {
                  props.onChange(e);
                  //forceUpdate();
                  setToothNumber(props.activityObj.toothNumber);
                }}
                label="Tooth Number"
                value={toothNumber ? toothNumber : ""}
              >
                <MenuItem value="11">
                  11- Upper right permanent central incisor
                </MenuItem>
                <MenuItem value="12">
                  12- Upper right permanent lateral incisor
                </MenuItem>
                <MenuItem value="13">13- Upper right permanent canine</MenuItem>
                <MenuItem value="14">
                  14- Upper right permanent 1st premolar
                </MenuItem>
                <MenuItem value="15">
                  15- Upper right permanent 2nd premolar
                </MenuItem>
                <MenuItem value="16">
                  16- Upper right permanent 1st molar
                </MenuItem>
                <MenuItem value="17">
                  17- Upper right permanent 2nd molar
                </MenuItem>
                <MenuItem value="18">
                  18- Upper right permanent 3rd molar
                </MenuItem>
                <MenuItem value="21">
                  21- Upper left permanent central incisor
                </MenuItem>
                <MenuItem value="22">
                  22- Upper left permanent lateral incisor
                </MenuItem>
                <MenuItem value="23">23- Upper left permanent canine</MenuItem>
                <MenuItem value="24">
                  24- Upper left permanent 1st premolar
                </MenuItem>
                <MenuItem value="25">
                  25- Upper left permanent 2nd premolar
                </MenuItem>
                <MenuItem value="26">
                  26- Upper left permanent 1st molar
                </MenuItem>
                <MenuItem value="27">
                  27- Upper left permanent 2nd molar
                </MenuItem>
                <MenuItem value="28">
                  28-Upper left permanent 3rd molar
                </MenuItem>
                <MenuItem value="31">
                  31- Lower left permanent central incisor
                </MenuItem>
                <MenuItem value="32">
                  32- Lower left permanent lateral incisor
                </MenuItem>
                <MenuItem value="33">33- Lower left permanent canine</MenuItem>
                <MenuItem value="34">
                  34- Lower left permanent 1st premolar
                </MenuItem>
                <MenuItem value="35">
                  35- Lower left permanent 2nd premolar
                </MenuItem>
                <MenuItem value="36">
                  36- Lower left permanent 1st molar
                </MenuItem>
                <MenuItem value="37">
                  37- Lower left permanent 2nd molar
                </MenuItem>
                <MenuItem value="38">
                  38- Lower left permanent 3rd molar
                </MenuItem>
                <MenuItem value="41">
                  41- Lower right permanent central incisor
                </MenuItem>
                <MenuItem value="42">
                  42- Lower right permanent lateral incisor
                </MenuItem>
                <MenuItem value="43">43- Lower right permanent canine</MenuItem>
                <MenuItem value="44">
                  44- Lower right permanent 1st premolar
                </MenuItem>
                <MenuItem value="45">
                  45- Lower right permanent 2nd premolar
                </MenuItem>
                <MenuItem value="46">
                  46- Lower right permanent 1st molar
                </MenuItem>
                <MenuItem value="47">
                  47- Lower right permanent 2nd molar
                </MenuItem>
                <MenuItem value="48">
                  48- Lower right permanent 3rd molar
                </MenuItem>
                <MenuItem value="51">
                  51- Upper right primary central incisor
                </MenuItem>
                <MenuItem value="52">
                  52- Upper right primary lateral incisor
                </MenuItem>
                <MenuItem value="53">53- Upper right primary canine</MenuItem>
                <MenuItem value="54">
                  54- Upper right primary first molars
                </MenuItem>
                <MenuItem value="55">
                  55- Upper right primary second molars
                </MenuItem>
                <MenuItem value="61">
                  61- Upper left primary central incisor
                </MenuItem>
                <MenuItem value="62">
                  62- Upper left primary lateral incisor
                </MenuItem>
                <MenuItem value="63">63- Upper left primary canine</MenuItem>
                <MenuItem value="64">
                  64- Upper left primary first molars
                </MenuItem>
                <MenuItem value="65">
                  65- Upper left primary second molars
                </MenuItem>

                <MenuItem value="71">
                  {" "}
                  71- Lower left primary central incisor{" "}
                </MenuItem>
                <MenuItem value="72">
                  {" "}
                  72- Lower left primary lateral incisor{" "}
                </MenuItem>
                <MenuItem value="73"> 73- Lower left primary canine </MenuItem>
                <MenuItem value="74">
                  {" "}
                  74- Lower left primary first molar
                </MenuItem>
                <MenuItem value="75">
                  {" "}
                  75- Lower left primary second molar{" "}
                </MenuItem>

                <MenuItem value="81">
                  81- Lower right primary central incisor
                </MenuItem>
                <MenuItem value="82">
                  82- Lower right primary lateral incisor
                </MenuItem>
                <MenuItem value="83">83- Lower right primary canine</MenuItem>
                <MenuItem value="84">
                  84- Lower right primary first molars
                </MenuItem>
                <MenuItem value="85">
                  85- Lower right primary second molars
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={(e) => {
              props.onClickAddActivity(e);
              // forceUpdate();
              setType("");
              setCode("");
              setPrice("");
              setQuantity("");
              setToothNumber("");
            }}
            sx={{ mt: 3, ml: 1 }}
          >
            Add Activity
          </Button>
        </Grid>

        {props.data &&
        props.data.AllActivity &&
        props.data.AllActivity.length > 0 ? (
          <ActivityTable
            AllActivity={props.data.AllActivity}
            deleteActivityElement={props.deleteActivityElement}
            deleteFlag={true}
          />
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
